import React from "react";
import { IDictionaryField } from "../../types";
import "./style.scss";

const genders: any = {
  1: "All",
  2: "Male",
  3: "Female",
};
interface IProps {
  currentData: any;
  dictInterest: IDictionaryField[];
  dictLanguages: IDictionaryField[];
}
const AudienceInfo: React.FC<IProps> = ({
  dictInterest,
  currentData: { targeting },
  dictLanguages,
}) => {
  const {
    toAge,
    fromAge,
    languages = [],
    interests: { include = [], exclude = [], includeAny = [] },
    websites,
    apps = [],
    gender,
  } = targeting;
  //@ts-ignore
  const cFilter = (entireObj: Array, keyToFind: string, valToFind: number) => {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    //@ts-ignore
    return foundObj;
  };
  const filterLanguage = [...languages?.include, ...languages?.exclude].map(
    (e: any) => {
      const langs = cFilter(dictLanguages, "value", e);
      //@ts-ignore
      return langs?.title;
    }
  );
  const filterInterests = [...include, ...exclude, ...includeAny].map(
    (e: any) => {
      const langs = cFilter(dictInterest, "value", e);
      //@ts-ignore
      return langs?.title;
    }
  );
  const filterAppsAndWebsite = [
    ...apps.include,
    ...apps.exclude,
    ...websites.include,
    ...websites.exclude,
  ].map((e: any) => (typeof e === "object" && !Array.isArray(e) ? e.name : e));
  return (
    <div className="audience-info">
      {filterLanguage?.length || fromAge || toAge || gender ? (
        <div className="audience-info-basic-info">
          <h1 className="audience-info-basic-info-title">BASIC INFO</h1>
          <p className="audience-info-basic-info-description">
            <span>
              {`${fromAge} to ${toAge}`} age range,
              {` gender ${genders[gender]},`}
              {filterLanguage.filter(Boolean).length
                ? ` ${filterLanguage.join(" ")} selected`
                : ""}
            </span>
          </p>
        </div>
      ) : null}
      {filterInterests?.length ? (
        <div className="audience-info-interests">
          <h1 className="audience-info-interests-title">INTERESTS</h1>
          <p className="audience-info-interests-description">
            {filterInterests.join(", ")}
          </p>
        </div>
      ) : null}
      {filterAppsAndWebsite?.length ? (
        <div className="audience-info-apps-and-websites">
          <h1 className="audience-info-apps-and-websites-title">
            APPS AND WEBSITES
          </h1>
          <p className="audience-info-apps-and-websites-description">
            {filterAppsAndWebsite.join(", ")}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default AudienceInfo;
